import {createContext, useContext, useEffect, useState} from 'react';

const AuthContext = createContext();

export function AuthContextProvider({children}) {
    const [user, setUser] = useState(null)
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user) {
            setUser(user)
        } else {
            setUser(false)
        }
    }, [])


    return (
        <AuthContext.Provider value={{user, setUser}}>
            {children}
        </AuthContext.Provider>
    )
}

export default function useAuth() {
    return useContext(AuthContext)
}
