import React from 'react'
import {Navigate} from "react-router-dom";
import useLoader from "../hooks/useLoader";
import useAuth from "../context/authContext";


function AuthRoute({ children, restrictRole }) {
    const { user } = useAuth();
    const { isLoading, setLoading, Loader } = useLoader();

    if (user === null) {
        return <Loader />
    }

    if (user === false) {
        return <Navigate to="/login" />
    }

    const allowedRoles = restrictRole.split(' ');
    if (allowedRoles.length && !allowedRoles.includes(user.user.role)) {
        // Redirect to an unauthorized or default page
        return <Navigate to="/waste" />
    }

    return children;
}


export default AuthRoute
