import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import EmployeeService from '../services/EmployeeService';
import NavBar from './NavBar';

function AddEmployee() {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const navigate = useNavigate();
    const {id} = useParams();


    const saveOrUpdateEmployee = async (e) => {
        e.preventDefault();
        const employee = {username,email,name, surname, password, role};
        if (id) {
            try {
                // console.log(employee)
                const response = await EmployeeService.updateEmployee(id, employee);
               //console.log(response);
                navigate('/admin'); // Use navigate function to navigate to a different route
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    setErrorMessage('Όνομα χρήστη ή εμαιλ υπάρχει ήδη');
                } else {
                    console.log(error);
                }
            }
        } else {
            try {
                const response = await EmployeeService.createEmployee(employee);
                // console.log('here ' + response.data);
                navigate('/admin'); // Use navigate function to navigate to a different route
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    setErrorMessage('Όνομα χρήστη ή εμαιλ υπάρχει ήδη');
                } else {
                    console.log(error);
                }
            }
        }
    }

    useEffect(() => {
        if (id) {
            console.log(id)
            EmployeeService.getEmployeeById(id)
                .then((response) => {
                    if (response) {
                        const {name, surname, email, username,role,password} = response.data.user;
                        // Check if response is not null or undefined
                        setName(name || '');
                        setSurname(surname || '');
                        setEmail(email || '');
                        setUsername(username || '')
                        setRole(role || '')
                        setPassword(password || '')
                        //console.log(name)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [id]); // Include id in the dependency array to re-run the effect when it changes

    const title = () => {
        if (id) {
            return <h2 className='text-center'>Ενημέρωση Εργαζομένου</h2>;
        } else {
            return <h2 className='text-center'>Προσθήκη Εργαζομένου</h2>;
        }
    }

    return (
        <div>
            <NavBar/>
            <br/>
            <br/>
            <div style={{padding: '20px', backgroundColor: '#f4f4f4'}} className='card col-md-6 offset-md-3 offset-md-3'>
                {title()}
                <div className='card-body'>
               
                    <form>
                        <div className='form-group mb-2'>
                            <label className='form-label'></label>
                            <input type='text' placeholder='Όνομα' name='firstName' className='form-control'
                                   value={name} onChange={(e) => setName(e.target.value)}></input>
                        </div>
                        <div className='form-group mb-2'>
                            <label className='form-label'></label>
                            <input type='text' placeholder='Επώνυμο' name='lastName' className='form-control'
                                   value={surname} onChange={(e) => setSurname(e.target.value)}></input>
                        </div>
                        <div className='form-group mb-2'>
                            <label className='form-label'></label>
                            <input type='text' placeholder='Email' name='emailId' className='form-control' value={email}
                                   onChange={(e) => setEmail(e.target.value)}></input>
                        </div>
                        <div className='form-group mb-2'>
                            <label className='form-label'></label>
                            <input type='text' placeholder='Όνομα χρήστη' name='username' className='form-control'
                                   value={username} onChange={(e) => setUsername(e.target.value)}></input>
                        </div>
                        <div className='form-group mb-2'>
                            <label className='form-label'></label>
                            <input type='password' placeholder='Κωδικός Πρόσβασης' name='password' className='form-control'
                                   value={password} onChange={(e) => setPassword(e.target.value)}></input>
                        </div>
                        <div style={{marginTop: '20px'}} className='form-group mb-2'>
                            <select value={role} onChange={(e) => setRole(e.target.value)}>
                                <option value="">-- Θέση Εργασίας --</option>
                                <option value="admin">Διαχειριστής </option>
                                <option value="user">Χρήστης </option>
                            </select>
                        </div>

                        <button style={{margin: '20px 20px 20px 0'}} className='btn btn-success' onClick={(e) => saveOrUpdateEmployee(e)}>Υποβολή</button>
                        <button  style={{backgroundColor: 'red'}} className='btn btn-danger md-2' onClick={() => navigate('/admin')}>Ακύρωση</button>
                        {errorMessage && (
                            <div style={{ textAlign: 'center', color: 'red', marginTop: '20px',fontSize: '25px' }}>
                                {errorMessage}
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddEmployee;
