import React from 'react';
import axios from 'axios';

const EMPLOYEE_BASE_REST_API_URL = 'https://submit.enssol.tech/api/v1/user/';

class EmployeeService{
    getAllUsers(){
        return axios.get(EMPLOYEE_BASE_REST_API_URL);
    }
    createEmployee(employee){
        return axios.post(EMPLOYEE_BASE_REST_API_URL+`register/`,employee);
    }
    getEmployeeById(employeeId){
        return axios.get(EMPLOYEE_BASE_REST_API_URL+employeeId);
    }
    updateEmployee(employeeId,employee){
        return axios.put(EMPLOYEE_BASE_REST_API_URL+employeeId,employee);
    }
    deleteEmployee(employeeId){
        return axios.delete(EMPLOYEE_BASE_REST_API_URL+employeeId);
    }
}
export default new EmployeeService();
