import React, {useEffect, useState} from 'react';
import './css/Home.css'
 import NavBar from './NavBar';
import { downloadExcel } from "react-export-table-to-excel";
import {DateRangePicker} from 'rsuite';

import "rsuite/dist/rsuite.css";
import { Loader } from 'rsuite';

import {useNavigate} from "react-router-dom";
import {GreenButton} from "../components/buttonStyles";
import {  isWithinInterval,startOfDay } from 'date-fns';
import jsPDF from "jspdf";
import img from "./image";
import amiriFont from "../assets/amiriF";

import WasteMap from "./WasteMap";
import useAuth from "../context/authContext";
import * as XLSX from 'xlsx';
const RoutesList = () => {

    const [routes, setRoutes] = useState([])
    const {user} = useAuth()

    // const {logout} = useLogout()
    const [isLoading, setIsLoading] = useState(true);

    const [originalRoutes, setOriginalRoutes] = useState([]); 
    const [searchVehicle, setsearchVehicle] = useState('');
    const [searchDriver, setsearchDriver] = useState('');
    // const [searchType, setsearchType] = useState('');
    const [searchLocation, setsearchLocation] = useState('')
    const [searchTermBinType,setsearchTermBinType]=useState('')
    const [searchTermRoutebinid,setsearchTermRoutebinid]=useState('')
    const [searchConstant, setsearchConstant] = useState('')


    const navigate = useNavigate();

    useEffect(() => {
        const fetchRoutes = async () => {
            setIsLoading(true); // Start loading
            const response = await fetch('https://submit.enssol.tech/api/v1/routes');
            const json = await response.json();
            if (response.ok) {
                setRoutes(json.routes);
                setOriginalRoutes(json.routes);
            }
            setIsLoading(false); // Stop loading
        }
        
        fetchRoutes()
    }, [])


///////// handle date select /////////////////////////
const [sDate, setsDate] = useState(null);

const handleSelect = (date) => {
    if (!date || date.length === 0) {
      // Use the original data as the current filtered data
      setRoutes(originalRoutes);

    } else {
        setsDate(date)
      const selection0 = startOfDay(new Date(date[0]));
      const selection1 = startOfDay(new Date(date[1]));

      const filtered = originalRoutes.filter((route) => {
      const routeDate = startOfDay(new Date(route.date));

        // Compare dates with time taken into account
        return isWithinInterval(routeDate, { start: selection0, end: selection1 });
      });

      setRoutes(filtered);
    }
    setCurrentPage(1);
  };
  ////////////// handle date select /////////////////


/////////// download excel //////////////////////


function smartConvertDecimalStringToInteger(decimalString) {
    // Check if the string contains a decimal point
    if (decimalString.includes('.')) {
        // Get the position of the decimal point
        const decimalPosition = decimalString.indexOf('.');

        // Check how many digits are after the decimal point
        const digitsAfterDecimal = decimalString.length - decimalPosition - 1;

        // If there are 1 or 2 digits after the decimal, treat it as thousands separator
        if (digitsAfterDecimal === 1 || digitsAfterDecimal === 2) {
            // Convert by treating decimal as thousands separator
            const floatNumber = parseFloat(decimalString);
            const integerNumber = Math.round(floatNumber * 1000);
            return integerNumber;
        }
        // Otherwise, it's a regular decimal and should be treated as is
        else {
            return parseFloat(decimalString);
        }
    } else {
        // No decimal point, treat as a whole number
        return parseInt(decimalString, 10);
    }
}




const parseDateStringToDate = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    // Convert date to Excel date format
    return XLSX.SSF.format("yyyy-mm-dd", XLSX.utils.date_num(date));
};
    const header=['A/A','Blockchain ID','Ημέρα','Ώρα','Όχημα','Οδηγός','Τοποθεσία','Θέση GPS','Βάρος(kg)','RFID','Τύπος']
    let index=0;
    const body=routes.map(({_id,comments,co,__v, ...rest})=>{

        return [index+=1,rest.hash,parseDateStringToDate(rest.date),rest.time,rest.vehicle,rest.driver,rest.location,rest.coords,smartConvertDecimalStringToInteger(rest.kg),rest.routebinid,rest.bintype]
    })

 
    function handleDownloadExcel() {

        // Creating a new workbook
        const wb = XLSX.utils.book_new();

        // Adding header to worksheet
        const ws_data = [header, ...body];
        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        // Append worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, "Λίστα Διαδρομών");

        // Write workbook and trigger download
        XLSX.writeFile(wb, "Λίστα Διαδρομών.xlsx");
}

////////// download excel////////////////////////

////////////download and create pdf///////////
// const navigate = useNavigate();

const createAndDownloadWastePDF =  () => {

    const header=['A/A','Blockchain ID','Ημέρα','Ώρα','Όχημα','Οδηγός','Τοποθεσία','Θέση GPS','Βάρος(kg)','RFID','Τύπος']
    let index=0;
    const body=routes.map(({_id,comments,co,__v, ...rest})=>{

        return [index+=1,rest.hash,rest.date,rest.time,rest.vehicle,rest.driver,rest.location,rest.coords,rest.kg,rest.routebinid,rest.bintype]
    })

    const doc = new jsPDF( {
        orientation: 'landscape'
    }); // Set A4 paper size in portrait mode (default)

    doc.addImage(
        img,
        'PNG',
        14, 10, 50, 35
    );
    doc.addFileToVFS('../assets/Arimo-Regular.ttf', amiriFont); // Replace 'YourGreekFont.ttf' with the actual filename
    doc.addFont('../assets/Arimo-Regular.ttf', 'Amiri', 'normal');
    doc.setFont("Amiri");

    const date=new Date().toLocaleDateString();
    const newText='Εκδόθηκε: '+date

    if(sDate){
        const selection0 = startOfDay(new Date(sDate[0])).toLocaleDateString();
        const selection1 = startOfDay(new Date(sDate[1])).toLocaleDateString();
        const select1='Από: '+selection0
        const select2='Έως: '+selection1
        doc.text(170,55,select1); // Greek text
        doc.text(170,63,select2); // Greek text
    }

    // Add Greek text
    doc.text(170,35,'Αναφορά Λίστας Διαδρομών'); // Greek text
    doc.text(170,45,newText); // Greek text
    const text = 'Αναφορά Λίστας Διαδρομών';
    const fontSize = 6; // Adjust the font size as needed
    const textWidth = doc.getStringUnitWidth(text) * fontSize;

    // Calculate the position for the underline
    const underlineY = 35; // Adjust the Y-coordinate as needed

    // Draw the underline
    doc.setLineWidth(0.05); // Adjust the line width as needed
    doc.line(170, underlineY, 165 + textWidth, underlineY);
    const options = {
        // theme: 'grid',
        margin: { top: 40 }, // Adjust top margin as needed
        startY: 70, // Adjust starting Y-coordinate as needed
        tableWidth: 'auto',
        headStyles: {
          font: 'Amiri' ,
          fontSize: 3, // Adjust font size for headers
          fillColor: [33, 93, 15], // Specify the background color in RGB format (blue)rgb(33, 93, 15)
          textColor: [255, 255, 255], // Specify the text color (white)
        },
        bodyStyles: {
          fontSize: 4, // Adjust font size for data
          font: 'Amiri'
        },
      };


      doc.autoTable(header,body,options);

      const footerText = 'Πλατφόρμα Ροής Απορριμάτων Τεχνολογίας Blockchain - https://blockchain.enssol.tech \n © 2023 ENSSOL A.E. All rights reserved - central@enssol.gr';
      const pageWidth = doc.internal.pageSize.width; // Get the width of the page
      const pageHeight = doc.internal.pageSize.height; // Get the height of the page
      const fontSize1 = 6;

        // Add content to the PDF document with the specified font size
     doc.setFontSize(fontSize1);
      // Position the footer text at the bottom of the page
      doc.text(footerText, pageWidth / 2, pageHeight - 10,'center');
      doc.save('report.pdf');

}


////////////////donwload and create pdf/////////

//const [markers, setMarkers] = useState([]); // This should be set with your coordinates

/////////////filter data /////////////////////
    const filterData = routes.filter((route) => {
        const conditions = [];

        if (searchVehicle) {
            conditions.push(route.vehicle.toLowerCase().includes(searchVehicle.toLowerCase()));
        }

        if (searchDriver) {
            conditions.push(route.driver.toLowerCase().includes(searchDriver.toLowerCase()));
        }

        if (searchLocation) {
            conditions.push(route.location.toLowerCase().includes(searchLocation.toLowerCase()));
        }

        if (searchTermBinType) {
            conditions.push(route.bintype.toLowerCase().includes(searchTermBinType.toLowerCase()));
        }

        if (searchTermRoutebinid) {
            conditions.push(route.routebinid.toLowerCase().includes(searchTermRoutebinid.toLowerCase()));
        }
        
        return conditions.every((condition) => condition);
    });

    const [selectedCoordinates, setSelectedCoordinates] = useState([]);  // Default coordinates

    useEffect(() => {
        // Add any additional logic you need here, such as setting the filter results
        const coordinates =filterData.map(data=>{

                let normalizedCoords = data.coords.toString().replace(/[\[\]]/g, '');
                // Split the normalized string by comma, potentially surrounded by spaces
                const coordinateArray = normalizedCoords.split(/\s*,\s*/);
            
                // Extract the lat and lng values
                const lat = parseFloat(coordinateArray[0]);
                const lng = parseFloat(coordinateArray[1]);
                //console.log("Latitude: "+ lat + " Longitude: " + lng)
                return {lat,lng}
                
        })    

        setSelectedCoordinates(coordinates)


    }, [searchVehicle, searchDriver, searchLocation, searchTermBinType, searchTermRoutebinid,routes]);

    const [selectCo,setselectCo]=useState(null)

    const handleCoordinatesChange = (newCoordinates) => {

                let normalizedCoords = newCoordinates.toString().replace(/[\[\]]/g, '');    
                // Split the normalized string by comma, potentially surrounded by spaces
                const coordinateArray = normalizedCoords.split(/\s*,\s*/);
            
                // Extract the lat and lng values
                const lat = parseFloat(coordinateArray[0]);
                const lng = parseFloat(coordinateArray[1]);

               // console.log("Latitude: "+ lat + " Longitude: " + lng)
                setselectCo({ lat: lat, lng: lng });
    

    }

   
    const [selectedItem, setSelectedItem] = useState(null);

    // Function to update the selected item
    const handleSelectItem = (item) => {
    setSelectedItem(item);
    };

 
    function areCoordinatesEqual(coord1, coord2) {

        const array = Object.values(coord1);

        let normalizedcoord1 = array.toString().replace(/[\[\]]/g, '');          
        const coordinate1 = normalizedcoord1.split(/\s*,\s*/);        
        const lat1 = parseFloat(coordinate1[0]);
        const lng1 = parseFloat(coordinate1[1]);

        let normalizedcoord2 = coord2.toString().replace(/[\[\]]/g, '');          
        const coordinate2 = normalizedcoord2.split(/\s*,\s*/);        
        const lat2 = parseFloat(coordinate2[0]);
        const lng2 = parseFloat(coordinate2[1]);

        if (!coordinate1 || !coordinate2) return false;
        return lat1 === lat2 && lng1 === lng2;
      }
  
      const [originalWastesCoordinates, setOriginalWastesCoordinates] = useState([]);

      useEffect(() => {
        const fetchRoutes = async () => {
          const response = await fetch('https://submit.enssol.tech/api/v1/routes/');
          const json = await response.json();
          if (response.ok) {
            setRoutes(json.routes)
            setOriginalRoutes(json.routes);
      
            // Extract coordinates from the original wastes and set them
            const coordinates = json.routes.map(route => {
              let normalizedCoords = route.coords.toString().replace(/[\[\]]/g, '');
              const coordinateArray = normalizedCoords.split(/\s*,\s*/);
              const lat = parseFloat(coordinateArray[0]);
              const lng = parseFloat(coordinateArray[1]);
              return { lat, lng };
            });
            setOriginalWastesCoordinates(coordinates);
          }
        };
        fetchRoutes();
      }, []);

      const [resetMap,setResetMap]=useState(false)
      const [mapKey, setMapKey] = useState(0);
      const handleResetMap = () => {
        setselectCo(null);
        setSelectedCoordinates(originalWastesCoordinates);
        setMapKey(prevKey => prevKey + 1); // Change the key to force re-render
      };
      
      
      useEffect(() => {
        if (resetMap) {
          // Additional logic if needed when resetMap is true
          setResetMap(false); // Set resetMap back to false
        }
       }, [resetMap]);


        function onMapReset  () {
        setResetMap(false)
        }
      const [currentPage, setCurrentPage] = useState(1);
     const [itemsPerPage, setItemsPerPage] = useState(10);  // Adjust this number as needed

     const indexOfLastItem = currentPage * itemsPerPage;
     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
     const currentItems = filterData.slice(indexOfFirstItem, indexOfLastItem);

     const handleMapMarkerClick = (coordinate) => {
        setselectCo(coordinate);
        
        // Find the index of the waste item with the selected coordinate
        const index = routes.findIndex(waste => areCoordinatesEqual(coordinate, waste.coords));
        
        if (index !== -1) {
          // Calculate the page number
          const pageNumber = Math.ceil((index + 1) / itemsPerPage);
          setCurrentPage(pageNumber);
        }
      };

    //   const [currentPage, setCurrentPage] = useState(1);
    // const [itemsPerPage, setItemsPerPage] = useState(10); // Adjust this number as needed
    const totalPages = Math.ceil(filterData.length / itemsPerPage);

    const renderPagination = () => {
        let startPage, endPage;
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 2 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }
    
        const pages = Array.from({ length: (endPage + 1) - startPage }).map((_, idx) => startPage + idx);
    
        return (
            <div className="pagination">
                <button 
                    onClick={() => setCurrentPage(1)} 
                    disabled={currentPage === 1}
                    style={paginationButtonStyle(currentPage === 1)}
                >
                    Αρχική
                </button>
                <button 
                    onClick={() => setCurrentPage(currentPage - 1)} 
                    disabled={currentPage === 1}
                    style={paginationButtonStyle(currentPage === 1)}
                >
                    Προηγούμενη
                </button>
                {currentPage > 3 && (
                    <>
                        <button 
                            onClick={() => setCurrentPage(1)}
                            style={paginationButtonStyle(false)}
                        >
                            1
                        </button>
                        <span>...</span>
                    </>
                )}
                {pages.map(page =>
                    <button 
                        key={page} 
                        onClick={() => setCurrentPage(page)}
                        style={paginationButtonStyle(page === currentPage)}
                    >
                        {page}
                    </button>
                )}
                {currentPage < (totalPages - 2) && (
                    <>
                        <span>...</span>
                        <button 
                            onClick={() => setCurrentPage(totalPages)}
                            style={paginationButtonStyle(false)}
                        >
                            {totalPages}
                        </button>
                    </>
                )}
                <button 
                    onClick={() => setCurrentPage(currentPage + 1)} 
                    disabled={currentPage === totalPages}
                    style={paginationButtonStyle(currentPage === totalPages)}
                >
                    Επόμενο
                </button>
                <button 
                    onClick={() => setCurrentPage(totalPages)} 
                    disabled={currentPage === totalPages}
                    style={paginationButtonStyle(currentPage === totalPages)}
                >
                    Τελευταία
                </button>
                <select 
  value={itemsPerPage} 
  onChange={(e) => setItemsPerPage(Number(e.target.value))} 
  style={{ margin: '10px' , padding: '5px' ,margin: '5px' ,border: '1px solid black' }}
>
  <option value={10}>10</option>
  <option value={20}>20</option>
  <option value={30}>30</option>
  <option value={50}>50</option>
  <option value={100}>100</option>
                                </select>
            </div>
        );
    };
    
    const paginationButtonStyle = (isActive) => ({
        padding: '5px', 
        margin: '5px', 
        border: '1px solid black', 
        background: isActive ? '#4CAF50' : 'white',
        color: isActive ? 'white' : 'black',
        cursor: 'pointer',
        fontFamily: 'Arial, sans-serif'
    });
     
    return (

        <div>
            <NavBar/>
            <div className='container3'>
                <div className='main-top' style={{width: '100vw'}}>
                    <h3>Λίστα Διαδρομών</h3>
                </div>
                <div className='add_btn mt-2 mb-2'></div>
                <section className='main'>
                    
           
                
                <div className="main-content">
             

                <table  className='table' id='EmployeeTable'>
                                <thead>
                                <tr>
                                    <th>Α/Α</th>
                                    <th>Blockchain id</th>
                                    <th>Ημέρα</th>
                                    <th>Ώρα</th>
                                    <th>Όχημα 
                                    <br />
                                        <input
                                            style={{width: "100px"}}
                                            type="text"
                                            placeholder="Αναζήτηση"
                                            value={searchVehicle}

                                            onChange={(e) => {
                                                setsearchConstant('vehicle')
                                                setsearchVehicle(e.target.value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </th>
                                    <th>
                                        Οδηγός
                                        <br />
                                        <input
                                            style={{width: "100px"}}
                                            type="text"
                                            placeholder="Αναζήτηση"
                                            value={searchDriver}
                                            onChange={(e) => {
                                                setsearchConstant('driver')
                                                setsearchDriver(e.target.value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </th>
                       
                                    
                                    <th>Τοποθεσία
                                    <br />
                                    <input
                                            style={{width: "100px"}}
                                            type="text"
                                            placeholder="Αναζήτηση"
                                            value={searchLocation}
                                            onChange={(e) => {
                                               
                                                setsearchConstant('location')
                                                setsearchLocation(e.target.value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </th>
                                        
                                    <th>
                                        Θέση GPS</th>
                                    <th>
                                        Βάρος(kg)
                                    </th>
                                    <th>RFID
                                    <br />
                                <input
                                    style={{width: "100px"}}
                                    type="text"
                                    placeholder="Αναζήτηση"
                                    value={searchTermRoutebinid}
                                    onChange={(e) => {
                                        setsearchTermRoutebinid(e.target.value);
                                        setsearchConstant('routebinid')
                                        setCurrentPage(1);
                                    }}
                                />
                            </th>
                            <th>Τύπος
                            <br />
                                <input
                                 style={{width: "100px"}}
                                    type="text"
                                    placeholder="Αναζήτηση"
                                    value={searchTermBinType}

                                    onChange={(e) => {
                                        setsearchTermBinType(e.target.value);
                                        setsearchConstant('bintype')
                                        setCurrentPage(1);
                                    }}
                                /></th>

                                </tr>
                                </thead>
                                <tbody>
                                {currentItems.map((route, index) => (
                                    <tr key={index} >
                                        <th scope='row'>{index + 1}</th>
                                        <td>
                                          <span
                                              className="trim-text"
                                              style={{cursor: 'pointer', fontWeight: 'bold', color: route.co === 1 ? 'red' : '' }}
                                              onClick={() => navigate('/routeDetails/' + route._id,{state:{co:route.co}})}
                                          >
                                            {route.hash?.length > 15 ? `${route.hash?.slice(0, 15)}...` : route.hash}
                                          </span>

                                        </td>
                                        <td>{route.date}</td>
                                        <td>{route.time}</td>
                                        <td>{route.vehicle}</td>
                                        <td>{route.driver}</td>
                                        <td>{route.location}</td>
                                        {/* <td>{waste.coords}</td> */}
                                        <td
                                         onClick={() => handleCoordinatesChange(route.coords)}
                                         style={{ cursor: 'pointer',backgroundColor: selectCo && areCoordinatesEqual(selectCo, route.coords) ? 'lightblue' : 'transparent' , textDecoration: 'underline' }}
                                     
                                        >{route.coords}</td>
                                      
                                        <td>{route.kg}</td>
                                        <td>{route.routebinid}</td>
                                        <td>{route.bintype}</td>
                                       
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            
                                {renderPagination()}
                                
                                
                        </div>    



              <GreenButton style={{
                            textTransform: 'none', margin: '20px 10px 0 10px'
                        }} onClick={createAndDownloadWastePDF}>
                            Εκτύπωση
                        </GreenButton>
                        <GreenButton style={{
                            textTransform: 'none', marginTop: '20px'
                        }} onClick={handleDownloadExcel}>
                            Εξαγωγή
                        </GreenButton>
               
                        <GreenButton style={{
                            textTransform: 'none', margin: '20px 10px 0 10px'
                        }} onClick={handleResetMap}>
                            Reset Map
                        </GreenButton>
                        <DateRangePicker onChange={handleSelect}
                        style={{marginLeft: '10px', border: '1px solid black', borderRadius: '5px', marginTop:'20px'}}
                         placeholder='Απο Ημ/νια - Εως Ημ/νια' format="dd-MM-yyyy"
                         ranges = {[

                            {
                                label:'Σήμερα',
                                value:[new Date(), new Date()]
                            },
                            {
                                label:'Εχτές',
                                value:[
                                new Date(new Date().setDate(new Date().getDate() - 1)),
                                new Date(new Date().setDate(new Date().getDate() - 1)),
                              ]
                            },
                            {
                                label: '1 εβδομάδα',
                                value: [
                                    new Date(new Date().setDate(new Date().getDate() - 6)),
                                    new Date(new Date())
                                ]
                            },
                            {
                                label:'1 μήνας',
                                value: [
                                    new Date(new Date().setDate(1)),
                                    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                                ]
                            }


                         ]}
                           
                      /> 
                               {/* </>
                )} */}

                
                        </section>
                        
                    <br/>
                    <WasteMap
                    key={mapKey}
                    coordinates={selectedCoordinates}
                    coordinate={selectCo}
                    data={routes}
                    resetMap={resetMap}
                    onResetDone={() => setResetMap(false)}
                    onSelectItem={handleSelectItem} 
                        selectedItem={selectedItem}
                        onMarkerClick={handleMapMarkerClick}
                    />
                </div>
            </div>
              

    )
}

export default RoutesList;
