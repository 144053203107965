import useAuth from "../context/authContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const useLogin = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [role, setRole] = useState(null);
    const { setUser } = useAuth();
    const navigate = useNavigate();

    const login = async (username, password) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch('https://submit.enssol.tech/api/v1/user/login', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({username, password})
            });

            const json = await response.json();
 
            if (response.ok) {
                setRole(json.user.role);
                localStorage.setItem('user', JSON.stringify(json));
                setUser(json);
                navigate('/waste');
            } else {
               // alert(response)
                switch (response.status) {
                    case 400:
                        setError('Λάθος Υποβολή. Ελέγξετε τα δεδομένα σας.');
                        break;
                    case 401:
                        setError('Μη εξουσιοδοτημένος. Λάθος όνομα χρήστη ή κωδικός πρόσβασης.');
                        break;
                    default:
                        setError('Παρουσιάστηκε ένα απροσδόκητο σφάλμα.');
                }
                setUser(false);
            }
        } catch (error) {
            setError('Failed to connect to the server.');
        } finally {
            setIsLoading(false);
        }
    };

    return { login, isLoading, error, role };
}
