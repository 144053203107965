import React, {useEffect, useState} from 'react'
import {useParams,useLocation} from "react-router-dom";
import axios from 'axios';
import {GreenButton} from "../components/buttonStyles";


const QrRoute = () => {
    const [route, setRoute] = useState({});
    const {id} = useParams();
    const routeID = id;

    useEffect(() => {
        const getOneRoute = async () => {
            try {
         
                
                const response = await axios.get(`https://submit.enssol.tech/api/v1/routes/${routeID}`);
  
                setRoute(response.data.route);
            } catch (error) {
                console.log(error.message);
            }
        };
        getOneRoute();
    }, [routeID]);


    return (
        <div className='container'>
        <div className='main-top' style={{ width: '100vw' }}>
          <h3>Πληροφορίες Διαδρομής</h3>
        </div>
        <div className="container mt-4 ml-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <p><strong>Blockchain id:</strong> <span className='form-control'>{route.hash}</span></p>
                  <p><strong>Ημέρα:</strong> <span className='form-control'>{route.date}</span></p>
                  <p><strong>Ωρα:</strong> <span className='form-control'>{route.time}</span></p>
                  <p><strong>Όχημα:</strong> <span className='form-control'>{route.vehicle}</span></p>
                  <p><strong>Οδηγός:</strong> <span className='form-control'>{route.driver}</span></p>
                  
                </div>
                <div className="col-md-6 col-sm-12">
                  <p><strong>Τοποθεσία:</strong> <span className='form-control'>{route.location}</span></p>
                  <p><strong>Θέση GPS:</strong> <span className='form-control'>{route.coords}</span></p>
                  <p><strong>Βάρος(kg):</strong> <span className='form-control'>{route.kg}</span></p>
                  <p><strong>RFID:</strong> <span className='form-control'>{route.routebinid}</span></p>
                  <p><strong>Τύπος Κάδου:</strong> <span className='form-control'>{route.bintype}</span></p>
            
                </div>
              </div>
              <br />
              <div className="col-md-8">
                {/* Your content here */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default QrRoute

