import React, {useEffect, useState} from 'react'
import {useParams,useLocation} from "react-router-dom";
import axios from 'axios';
import {GreenButton} from "../components/buttonStyles";


const QrWaste = () => {
    const [waste, setWaste] = useState({});
    const {id} = useParams();
    const wasteID = id;

    useEffect(() => {
        const getOneWaste = async () => {
            try {
             
                
                const response = await axios.get(`https://submit.enssol.tech/api/v1/wastes/${wasteID}`);
         
                setWaste(response.data.waste);
            } catch (error) {
                console.log(error.message);
            }
        };
        getOneWaste();
    }, [wasteID]);


    return (
        <div className='container'>
        <div className='main-top' style={{ width: '100vw' }}>
          <h3>Πληροφορίες Διαδρομής</h3>
        </div>
        <div className="container mt-4 ml-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <p><strong>Blockchain id:</strong> <span className='form-control'>{waste.hash}</span></p>
                  <p><strong>Ημέρα:</strong> <span className='form-control'>{waste.date}</span></p>
                  <p><strong>Ωρα:</strong> <span className='form-control'>{waste.time}</span></p>
                  <p><strong>Όχημα:</strong> <span className='form-control'>{waste.vehicle}</span></p>
                  <p><strong>Οδηγός:</strong> <span className='form-control'>{waste.driver}</span></p>
                  <p><strong>Τύπος Οχήματος:</strong> <span className='form-control'>{waste.type}</span></p>
                </div>
                <div className="col-md-6 col-sm-12">
                  <p><strong>Τοποθεσία:</strong> <span className='form-control'>{waste.location}</span></p>
                  <p><strong>Θέση GPS:</strong> <span className='form-control'>{waste.coords}</span></p>
                  <p><strong>Βάρος(kg):</strong> <span className='form-control'>{waste.kg}</span></p>
                  <p><strong>Υλικό:</strong> <span className='form-control'>{waste.material}</span></p>
                  <p><strong>Φορέας:</strong> <span className='form-control'>{waste.organisation}</span></p>
                  <p><strong>Παρατηρήσεις:</strong> <span className='form-control'>{waste.pass}</span></p>
                </div>
              </div>
              <br />
              <div className="col-md-8">
                {/* Your content here */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default QrWaste

