
import React, { useEffect, useState } from "react";
import NavBar from './NavBar';
import SearchIcon from "@mui/icons-material/Search";
import './css/AdminDashboard.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const SearchData = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // Updated error state
    const location = useLocation();

    const handleInputChange = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
    };

    const fetchSearchResults = async () => {
        try {
            const response = await axios.get(`https://submit.enssol.tech/api/v1/wastes/block/${searchTerm}`);
            if (response) {
                // Check if data.co equals 1
                if (response.data.waste.co === 1) {
                    setErrorMessage('Η καταχώριση δεν είναι έγκυρη');
                    setData(response.data.waste);
                    //setData([]); // Optionally clear the data
                } else {
                    setData(response.data.waste);
                    setErrorMessage(''); // Clearing error message in case of valid data
                }
            }
        } catch (error) {
            setData([]);
            setErrorMessage('Η καταχώριση δεν είναι έγκυρη'); // Set error message for fetch error
        }
    };

    return (
        <div>
            <NavBar />
            <div className='container3'>
                <div className='add_btn mt-2 mb-2'></div>
                <section className='main'>
                    <div className='main-top' style={{ width: '100vw' }}>
                        <div style={{ marginTop: '10px' }} className="search-bar">
                            <input
                                style={{ color: 'black' }}
                                className="search-input"
                                type="text"
                                placeholder="Αναζήτηση Id"
                                name="id"
                                value={searchTerm}
                                onChange={handleInputChange}
                            />
                            <div
                                style={{ cursor: 'pointer' }}
                                className="search-prefix"
                                onClick={fetchSearchResults}
                            >
                                <SearchIcon />
                            </div>
                        </div>
                    </div>
                    <table className='table' id='searchTable'>
                        <thead>
                            <tr>
                            <th>Blockchain id</th>
                            <th>Ημέρα</th>
                            <th>Ώρα</th>
                            <th>Όχημα</th>
                            <th> Οδηγός</th>
                           <th>Τύπος Οχήματος</th>
                            <th>Τοποθεσία</th>
                            <th> Θέση GPS</th>
                           <th> Βάρος</th>
                          <th> Υλικό</th>
                           <th> Φορέας</th>
                          <th> Παρατηρήσεις</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            {data &&
                                <>
                                
                                    <td>
                                          <span className="trim-text"
                                           style={{cursor: 'pointer', fontWeight: 'bold' ,color: data.co === 1 ? 'red' : ''}}
                                        //    onClick={() => navigate('/wasteDetails/' + data._id)}
                                          onClick={() => navigate('/wasteDetails/' + data._id,{state:{co:data.co}})} >
                                       
                                            {data.hash?.length > 15 ? `${data.hash?.slice(0, 15)}...` : data.hash}
                                                
                                          </span>
                                    </td>
                                    <td>{data.date}</td>
                                    <td>{data.time}</td>
                                    <td>{data.vehicle}</td>
                                    <td>{data.driver}</td>
                                    <td>{data.type}</td>
                                    <td>{data.location}</td>
                                    <td>{data.coords}</td>
                                    <td>{data.kg}</td>
                                    <td>{data.material}</td>
                                    <td>{data.organisation}</td>
                                    <td>{data.pass}</td>
                                </>
                           
                             }
                            </tr>
                        </tbody>
                    </table>
                    {errorMessage && (
    <div className="error-message-container">
        <b>{errorMessage}</b>
    </div>
)}

                </section>
                <br />
            </div>
        </div>
    );
};

export default SearchData;
