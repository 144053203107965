import useAuth from "../context/authContext";

export const useLogout = () => {
    const {setUser} = useAuth()
    const logout = () => {
        localStorage.removeItem('user')
        setUser(false)
    }
    return {logout}
}
