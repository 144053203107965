import React, {useEffect, useState} from 'react'


import {useParams,useLocation} from "react-router-dom";
import axios from 'axios';
import NavBar from './NavBar';
import QRCode from 'react-qr-code';
import {GreenButton} from "../components/buttonStyles";
import font from '../assets/SEGOEUI-normal';
import jsPDF from "jspdf";
import img from "./image";
import QRCode1 from 'qrcode-generator'; // Import the qrcode-generator library
import autoTable from 'jspdf-autotable'

const RoutDetails = () => {
    const [route, setRoute] = useState({});
    const [dataUrl, setDataUrl] = useState("");
    const location = useLocation();
   
    const {id} = useParams();
    console.log(location)
    // console.log('co '+co)
    const routeID = id;


    useEffect(() => {
        const getOneRoute = async () => {
            try {
                const response = await axios.get(`https://submit.enssol.tech/api/v1/routes/${routeID}`);
                //console.log(response.data.route)
                setRoute(response.data.route);
            } catch (error) {
                console.log(error.message);
            }
        };
        getOneRoute();
    }, [routeID]);

    const createAndDownloadWastePDF =  () => {

      const columns=['Blockchain ID','Ημέρα','Ώρα','Όχημα','Οδηγός','Τοποθεσία','Θέση GPS','Βάρος(kg)','RFID','Τύπος']
       
      const doc = new jsPDF('p', 'mm', 'a4');  // 'p' for portrait, 'mm' for millimeters, 'a4' for A4 page size
        

        doc.addFileToVFS('../assets/SEGOEUI-normal.ttf', font);
        doc.addFont('../assets/SEGOEUI-normal.ttf', 'SEGOEUI', 'normal');
        doc.setFont("SEGOEUI");
      
   
        const body=[route.hash,route.date,route.time,route.vehicle,route.driver,route.location,route.coords,route.kg,route.routebinid,route.bintype]
      
        const imageWidth = 100; // Set the width of the image
        const imageHeight = 50;
        // Calculate the X-coordinate to center the image
        const x = (doc.internal.pageSize.width - imageWidth) / 2;
    
        // Add the image to the PDF at the top center
        doc.addImage(img, 'PNG', x, 10, imageWidth, imageHeight);
        doc.text(x-10,70,'Πιστοποιημένη Αναφορά Ροών Απορριμάτων'); // Greek text

      
        const tableData=[[columns,body]]

        const mergedArray = columns.map((item, index) => [item, body[index]]);
       
   
   ///////////////////////////////////////////////////////////////////
   const tableX = 15;
   let tableY = 80;
   

// Define a function to calculate the cell size based on content
const calculateCellSize = (content, maxWidth, maxHeight) => {
  // const tempDoc = new jsPDF();
  const fontSize = 10; // Start with a reasonable font size
  doc.setFontSize(fontSize);
  const cellWidth = doc.getStringUnitWidth(content) * fontSize;
  return {
    width: cellWidth < maxWidth ? cellWidth : maxWidth,
    height: maxHeight,
  };
};

// Define a function to generate the table
const generateTable = (data) => {
  doc.setFont('SEGOEUI', 'normal');
  doc.setFontSize(doc.internal.getFontSize() - 4); // Reduce the font size by 2

  // Calculate the maximum width of the labels
  let maxLabelWidth = 0;
  data.forEach((row) => {
    const labelWidth = doc.getStringUnitWidth(row[0]) * doc.internal.getFontSize();
    if (labelWidth > maxLabelWidth) {
      maxLabelWidth = labelWidth;
    }
  });

  // Define the space between the label and the value (reduced spacing)
  const labelValueSpacing = -20; // Adjust as needed to reduce spacing

  // Define the row height
  const rowHeight = 6; // Adjust as needed for the desired row height

  // Use the maximum label width to align the values
  data.forEach((row, rowIndex) => {
    let currentX = tableX;

    // Split the row into label and value
    const [label, value] = row;

    // Set the fill color and text color for the first row
    if (rowIndex === 0) {
      doc.setFillColor(0, 128, 0); // Green background
      doc.setTextColor(255, 255, 255); // White text color
    } else {
      doc.setFillColor(255, 255, 255); // White background for other rows
      doc.setTextColor(0, 0, 0); // Black text color for other rows
    }

    // Draw the background rectangle
    doc.rect(tableX, tableY, maxLabelWidth + labelValueSpacing + doc.getStringUnitWidth(value.toString()) * doc.internal.getFontSize(), rowHeight, 'F');

    // Draw the label
    doc.text(label, currentX, tableY + rowHeight / 2, { align: 'left', baseline: 'middle' });
    const labelWidth = doc.getStringUnitWidth(label) * doc.internal.getFontSize();

    // Align the value to start after the widest label
    const valueX = tableX + maxLabelWidth + labelValueSpacing - 10;
    doc.text(value.toString(), valueX, tableY + rowHeight / 2, { align: 'left', baseline: 'middle' });

    // Set the x position for the next row, if you have more columns to draw
    currentX += maxLabelWidth + labelValueSpacing;

    // Move to the next row
    tableY += rowHeight; // No need to add extra padding since rowHeight includes it
  });
};

// Generate the table
generateTable(mergedArray);


///////////////////////////////////////


        const url = `https://blockchain.enssol.tech/QrRoute/${route._id}`
      

        const qr = QRCode1(0, 'L'); // Create a QR code instance (0 for QR code version 1, 'L' for error correction level)
        qr.addData(url); // Set the data you want to encode in the QR code
        qr.make(); // Generate the QR code
      
        // Get the QR code image as a base64 data URL
        const qrImage = qr.createDataURL(4); // 4 is the size of the QR code modules
      
        // Define the position and size of the QR code on the PDF
        const qrX = 130; // X-coordinate
        const qrY = 220; // Y-coordinate
        const qrSize = 50; // Size of the QR code
      
        // Add the QR code to the PDF
          doc.addImage(qrImage, 'PNG', qrX, qrY, qrSize, qrSize);
          const footerText = 'ENSSOL A.E.- Balcan Center, Επίπεδο 1, Πτέρυγα Δ,Χώρος 9, Τ.Κ.:57001 \n email: central@enssol.gr';
          const pageWidth = doc.internal.pageSize.width; // Get the width of the page
          const pageHeight = doc.internal.pageSize.height; // Get the height of the page
          const fontSize1 = 5;

            // Add content to the PDF document with the specified font size
         doc.setFontSize(fontSize1);
          // Position the footer text at the bottom of the page
        doc.text(footerText, pageWidth / 2, pageHeight - 10,'center');
        doc.setLineWidth(2); // Line thickness
        doc.setDrawColor(33, 93, 15); // Green color (R, G, B)

        // Draw a horizontal line near the bottom of the page as a footer
        const lineHeight = 10; // Height of the line
 
         doc.line(10, pageHeight - lineHeight -10, 200, pageHeight - lineHeight-10);

         const footerText_new = 'Μπορείτε να ελέγξετε την ισχύ του εγγράφου \n σκανάροντας το QR code ή εισάγοντας τον κωδικό\n στο https://blockchain.enssol.tech/search';
         const fontSize_new = 7;
         doc.setFontSize(fontSize_new);
         doc.text(footerText_new, qrX-40, qrY+30 ,'center');
    
        doc.save('report.pdf');
       
    }


    return (
        <div>
                 
            <NavBar/>
            <div className='container3'>
                <div className='main-top' style={{width: '100vw'}}>
                    <h3>Πληροφορίες Διαδρομής</h3>
                </div>
                <div className="container mt-4 ml-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    {/*<p><strong>ID:</strong><p className='form-control'> {waste._id}</p></p>*/}
                                    <p  ><strong >Blockchain id:</strong><p className='form-control' style={{color: location.state.co === 1 ? 'red' : ''}}> {route.hash}</p></p>
                                    <p><strong>Ημέρα:</strong><p className='form-control'> {route.date}</p></p>
                                    <p><strong>Ωρα:</strong><p className='form-control'> {route.time}</p></p>
                                    <p><strong>Όχημα:</strong><p className='form-control'> {route.vehicle}</p></p>
                                    <p><strong>Οδηγός:</strong><p className='form-control'> {route.driver}</p></p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Τοποθεσία:</strong><p className='form-control'> {route.location}</p></p>
                                    <p><strong>Θέση GPS:</strong><p className='form-control'> {route.coords}</p></p>
                                    <p><strong>Βάρος(kg):</strong><p className='form-control'> {route.kg}</p></p>
                                    <p><strong>RFID:</strong><p className='form-control'> {route.routebinid}</p></p>
                                    <p><strong>Tύπος Κάδου:</strong><p className='form-control'> {route.bintype}</p></p>
                                  
                                </div>
                                {location.state.co == 0 ? <GreenButton style={{
                                 textTransform: 'none', margin: '20px 10px 0 0px'
                                         }}  onClick={createAndDownloadWastePDF}>
                                     Εκτύπωση
                                     </GreenButton> : <p> </p>}
                        
                      
                            </div>
                            <br/>
                            <div className="col-md-8">
                         { location.state.co == 0 ?  <QRCode
                        value={`https://blockchain.enssol.tech/QrRoute/${route._id}`}        
                        style={{marginLeft: '450px' }}/>  : 

                        <b style={{color:'red',fontSize: '30px', margin: '20px 10px 0 350px'}}> Η καταχώριση δεν είναι έγκυρη</b>

                        }
                      </div>
                        </div>
                    </div>
                   
                
                </div>
            </div>
        </div>
    )


}

export default RoutDetails

