import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
// import Admin from './pages/Admin';
import Wastelist from './pages/WasteList';
import RoutesList from './pages/RoutesList'
import AdminDashboard from './pages/AdminDashboard';
import AddEmployee from './pages/AddEmployee';
import AddWaste from './pages/AddWaste';
import LoginPage from './pages/LoginPage';
import LandingPage from './pages/LandingPage';
// import EmployeeDetails from './pages/EmployeeDetails';
// import UserDashboards from './pages/UserDashboards';
import RouteDetails from './pages/RouteDetails';
import WasteDetails from './pages/WasteDetails';
import AuthRoute from "./wrappers/AuthRoute";
import {AuthContextProvider} from "./context/authContext";
import QrWaste from './pages/QrWaste';
import SearchPage from "./pages/SearchPage";
import SearchRoute from "./pages/SearchPageRoute"
import QrRoute from "./pages/QrRoute"
import LocationsPage from './pages/LocationsPage';
import AddLocation from './pages/AddLocation';
// import FilterRoute from './pages/FilterRoute';

function App() {
    return (
        <AuthContextProvider>

        
            <div className='App'>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<LandingPage/>}/>
                       
                        {/*<Route path="/signin" element={<Auth/>}/>*/}
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/admin" element={
                            <AuthRoute restrictRole='admin'>
                                <AdminDashboard/>
                            </AuthRoute>}>
                        </Route>
                        <Route path="/search" element={
                            <AuthRoute restrictRole='admin'>
                                <SearchPage/>
                            </AuthRoute>}>
                            </Route>
                      
                            <Route path="/searchRoute" element={
                            <AuthRoute restrictRole='admin'>
                                <SearchRoute/>
                            </AuthRoute>}>
                        </Route>
                        {/* <Route path="/employeeDetails/:id" exact element={
                            <AuthRoute restrictRole='admin'>
                                <EmployeeDetails/>
                            </AuthRoute>}>
                        </Route> */}
                        <Route path="/add-employee" exact element={
                            <AuthRoute restrictRole='admin'>
                                <AddEmployee/>
                            </AuthRoute>}>
                        </Route>
                        <Route path="/edit-employee/:id" exact element={
                            <AuthRoute restrictRole='admin'>
                                <AddEmployee/>
                            </AuthRoute>}>
                        </Route>
                        <Route path="/routeDetails/:id" exact element={
                            <AuthRoute restrictRole='admin user'>
                                <RouteDetails/>
                            </AuthRoute>}>
                        </Route>
                        <Route path="/wasteDetails/:id" exact element={
                             <AuthRoute restrictRole='admin user'>
                                <WasteDetails/> 
                            </AuthRoute>}>
                         </Route>
                         
                        <Route path="/QrWaste/:id" element={<QrWaste/>}/>
                        <Route path="/QrRoute/:id" element={<QrRoute/>}/>
                        <Route path="/waste" exact element={
                            <AuthRoute restrictRole='admin user'>
                                <Wastelist/>
                            </AuthRoute>}>
                        </Route>
                        <Route path="/route" exact element={
                            <AuthRoute restrictRole='admin user'>
                                <RoutesList/>
                            </AuthRoute>}>
                        </Route>

                        <Route path="/location" exact element={
                            <AuthRoute restrictRole='admin'>
                                <LocationsPage/>
                            </AuthRoute>}>
                        </Route>

                        <Route path="/add-location" exact element={
                            <AuthRoute restrictRole='admin'>
                                <AddLocation/>
                            </AuthRoute>}>
                        </Route>

                        <Route path="/edit-location/:id" exact element={
                            <AuthRoute restrictRole='admin'>
                                <AddLocation/>
                            </AuthRoute>}>
                        </Route>



                        <Route path="/add-waste" exact element={
                            <AuthRoute restrictRole='admin user'>
                                <AddWaste/>
                            </AuthRoute>}>
                        </Route>
                        {/* <Route path="/userdashboard" element={<UserDashboards/>}></Route> */}
                        {/* <Route path="/filter-route" element={<FilterRoute/>}></Route> */}
                        {/* <Route path="/" element={<Home />} />
          <Route path="/signin" element={ <Auth/>  } />

          <Route path="/about" element={<About />} />
          {/* <Route path="/admin" element={  <Protected><Dashboard /></Protected> } />  */}
                        {/* <Route path="/admin" element={  <Dashboard /> } />

          <Route path="/contact" element={<Contact />} />
          <Route path="/userwaste" element={ <UserW/> } />
          <Route path="/useroute" element={<UserR /> } />
          <Route path="/add-employee" element={<AddEmployee/>}></Route>
          <Route path="/add-waste" element={<AddWaste/>}></Route>
          <Route path="/edit-employee/:id" element={<AddEmployee/>}></Route> */}
                    </Routes>
                    {/* <Footer /> */}
                </BrowserRouter>

            </div>
                    
       
        </AuthContextProvider>
    );
}

export default App;
