import React, { useState } from 'react';
import {
    Box,
    CircularProgress,
    Container,
    CssBaseline,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    Alert
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import img from "../assets/thumbnail.png";
import logo from "../assets/image1.png";
import { GreenButton } from '../components/buttonStyles';
import { useLogin } from '../hooks/useLogin';
import manual from '../assets/manual.pdf';

const defaultTheme = createTheme();

const LoginPage = () => {
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [toggle, setToggle] = useState(false);
    const { login, isLoading, error } = useLogin();

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = manual;
        link.download = 'Εγχειρίδιο Χρήσης.pdf';
        link.click();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const username = e.target.username.value;
        const password = e.target.password.value;

        setUsernameError(!username);
        setPasswordError(!password);

        if (username && password) {
            await login(username, password);
        }
    };

    const handleInputChange = (event) => {
        const { name } = event.target;
        if (name === 'username') setUsernameError(false);
        if (name === 'password') setPasswordError(false);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" spacing={2} sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid item xs={12} md={4} sx={{
                    my: 'auto',
                    mx: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginLeft: '10px'
                }}>
                    <img src={img} alt="ensoll" style={{ width: '500px' }} />
                </Grid>
                <Container sx={{
                    my: 'auto',
                    mx: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Box sx={{
                        my: 'auto',
                        mx: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '10px'
                    }}>
                        <img src={logo} alt="ensoll" style={{ width: '200px' }} />
                        <Typography style={{ width: '80%', textAlign: 'center', margin: '15px' }} variant="h6">
                            Πλατφόρμα Διαχείρισης Απορριμμάτων Τεχνολογίας Blockchain
                        </Typography>

                        {error && <Alert severity="error">{error}</Alert>}

                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Όνομα Χρήστη"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                error={usernameError}
                                helperText={usernameError && 'Username is required'}
                                onChange={handleInputChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Κωδικός Πρόσβασης"
                                type={toggle ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                error={passwordError}
                                helperText={passwordError && 'Password is required'}
                                onChange={handleInputChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setToggle(!toggle)}>
                                                {toggle ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <GreenButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3 }}
                            >
                                {isLoading ? <CircularProgress size={24} color="inherit" /> : "Συνδεση"}
                            </GreenButton>

                            <button style={{
                                backgroundColor: '#fff',
                                color: 'blue',
                                textAlign: 'center',
                                width: '100%',
                                fontSize: '14px',
                                marginTop: '10px'
                            }} onClick={handleDownload}>Εγχειρίδιο Χρήσης
                            </button>
                        </Box>
                    </Box>
                </Container>
            </Grid>
        </ThemeProvider>
    );
}

export default LoginPage;