import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import EmployeeService from '../services/EmployeeService';
import NavBar from './NavBar';
import axios from 'axios';
function AddLocation() {
    const [name, setName] = useState('');
    const [gps, setGps] = useState('');


    const navigate = useNavigate();
    const {id} = useParams();


    const saveOrUpdateLocation = async (e) => {
        e.preventDefault();
        const location = {name, gps};
    
        if (id) {
            try {
                const response = await axios.put(`https://submit.enssol.tech/api/v1/locations/${id}`,location)
          
                navigate('/location'); // Use navigate function to navigate to a different route
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
      
                const response = await axios.post(`https://submit.enssol.tech/api/v1/locations/`,location)
                
                navigate('/location'); // Use navigate function to navigate to a different route
            } catch (error) {
                console.log(error);
            }
        }
    }
    useEffect(() => {
        // Define an async function inside useEffect
        const fetchData = async () => {
            if (id) {
                
                try {
                    const response = await axios.get(`https://submit.enssol.tech/api/v1/locations/${id}`);
                    if (response) {
                       
                        const { name, gps } = response.data.location;
                        // Check if response is not null or undefined
                        setName(name || '');
                        setGps(gps || '');
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        };
    
        // Call the async function
        fetchData();
    }, [id]); // Include id in the dependency array to re-run the effect when it changes
    
    const title = () => {
        if (id) {
            return <h2 className='text-center'>Ενημέρωση Τοποθεσίας</h2>;
        } else {
            return <h2 className='text-center'>Προσθήκη Τοποθεσίας</h2>;
        }
    }

    return (
        <div>
            <NavBar/>
            <br/>
            <br/>
            <div style={{padding: '20px', backgroundColor: '#f4f4f4'}} className='card col-md-6 offset-md-3 offset-md-3'>
                {title()}
                <div className='card-body'>
                    <form>
                        <div className='form-group mb-2'>
                            <label className='form-label'></label>
                            <input type='text' placeholder='Όνομα' name='firstName' className='form-control'
                                   value={name} onChange={(e) => setName(e.target.value)}></input>
                        </div>
                        <div className='form-group mb-2'>
                            <label className='form-label'></label>
                            <input type='text' placeholder='Συντεταγμενες' name='coords' className='form-control'
                                   value={gps} onChange={(e) => setGps(e.target.value)}></input>
                        </div>

                        <button style={{margin: '20px 20px 20px 0'}} className='btn btn-success' onClick={(e) => saveOrUpdateLocation(e)}>Υποβολή</button>
                        <button  style={{backgroundColor: 'red'}} className='btn btn-danger md-2' onClick={() => navigate('/location')}>Ακύρωση</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddLocation;
