import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NavBar from './NavBar';
import useAuth from "../context/authContext";
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./css/AddWaste.css"
import Modal from 'react-modal';

Modal.setAppElement('#root');


function AddWaste() {
    const { user } = useAuth()
    console.log(user)



    const [driver, setdriver] = useState('');
    const [vehicle, setvehicle] = useState('');
    const [location, setlocation] = useState('');
    const [coords, setcoords] = useState('');
    const [kg, setkg] = useState('');
    const [route, setroute] = useState('');
    const [pass, setpass] = useState('');
    const [type, settype] = useState('');
    const [material, setmaterial] = useState('');
    const [organisation, setorganisation] = useState('');
    const [adminId, setadminId] = useState(user.user.name);
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(false)
    const [locations, setLocations] = useState([])
    const [tempLocation, setTempLocation] = useState('');
    const [customLocation, setCustomLocation] = useState('');

    // State for the manual input when "Other" is selected
    const [manualInput, setManualInput] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [confirmCheck, setconfirmCheck] = useState(false);
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        // New useEffect for auto-closing the modal based on isLoading
        if (!isLoading) {
            closeModal();
        }
    }, [isLoading]);


    const currentDate = new Date();
    // Format the date as "yyyy-mm-dd"
    const formattedDate = currentDate.toISOString().split('T')[0];
    // Format the time as "HH:mm"
    const formattedTime = currentDate.toTimeString().split(' ')[0].substring(0, 5);

    const [date, setDate] = useState(formattedDate);
    const [time, setTime] = useState(formattedTime);
    useEffect(() => {
        const getLocation = async () => {
            try {
                const response = await axios.get(`https://submit.enssol.tech/api/v1/locations`);

                setLocations(response.data.locations);
            } catch (error) {

            }
        };
        getLocation();
    }, []);

    const navigate = useNavigate();
    // const { id } = useParams();

    const saveWaste = async (e) => {
        e.preventDefault();
        const finalLocation = location === 'Other' ? customLocation : location;
        const finalType = type === 'Other_new' ? manualInput : type;
        console.log(finalType)

        setIsLoading(true)
        setIsValid(true)

        if (location === 'Other') {

            const test_location = {
                name: finalLocation,
                gps: coords
            }
            await fetch('https://submit.enssol.tech/api/v1/locations', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(test_location)
            })
        }

        const waste = {
            date,
            time,
            driver,
            location: finalLocation,
            kg,
            pass,
            vehicle,
            coords,
            material,
            organisation,
            type: finalType,
            adminId
        }


        const response = await fetch('https://submit.enssol.tech/api/v1/wastes', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(waste)

        })

        const json = await response.json()

        if (response.ok) {
            //navigate('/waste'); // Use navigate function to navigate to a different route
            setIsLoading(false);
            setDate(formattedDate)
            setTime(formattedTime)
            setconfirmCheck(false);
            setdriver('')
            setlocation('')
            setManualInput('');
            setkg('')
            setpass('')
            setvehicle('')
            setcoords('')
            setmaterial('')
            setorganisation('')
            settype('')
            setIsValid(false)
        }
    }

    const handleLocationChange = (e) => {
        const selectedValue = e.target.value;
        setlocation(selectedValue);

        if (selectedValue === 'Other') {
            setCustomLocation('');
            setcoords('');
        } else {
            const selectedLocation = locations.find(loc => loc.name === selectedValue);
            setcoords(selectedLocation ? selectedLocation.gps : '');
        }
    };


    const handleTypeChange = (e) => {
        const selectedValue = e.target.value;
        settype(selectedValue); // Directly update the type state with the selected value

        if (selectedValue === 'Other_new') {
            // Additional logic for handling "Other_new", if necessary
        } else {
            // Reset manualInput if not "Other_new"
            setManualInput('');
        }
    };

    const [startDate, setStartDate] = useState(new Date());

    return (
        <div>
            <NavBar />
            <br />
            <br />
            <div className='container'>
                <div className='row'>
                    <div style={{ backgroundColor: '#f4f4f4' }} className='card col-md-6 offset-md-3 offset-md-3'>

                        <div className='card-body'>
                            <h5 style={{ textAlign: 'center' }}>Προσθήκη νέας Εγγραφής</h5>
                            <form style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '10px' }}>
                                <div className='form-group mb-2' >
                                    <label  className='form-label-custom' style={{ color: '#333' }} >Ημερομηνία</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control-custom"
                                        required
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <label htmlFor='time' className='form-label' style={{ color: '#333' }}>Ώρα</label>
                                    <input
                                        type='time'
                                        id='time'
                                        name='time'
                                        className='form-control'
                                        value={time}
                                        onChange={(e) => setTime(e.target.value)} 
                                        required
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='form-label'></label>
                                    <input type='text' placeholder='Όχημα' name='vehicle'
                                        className='form-control' value={vehicle}
                                        onChange={(e) => setvehicle(e.target.value)} required></input>
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='form-label'></label>
                                    <input type='text' placeholder='Οδηγός' name='driver'
                                        className='form-control' value={driver}
                                        onChange={(e) => setdriver(e.target.value)} required></input>
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='form-label'></label>
                                    <select
                                        name='type'
                                        className='form-control'
                                        value={type}
                                        // onChange={(e) => settype(e.target.value)}
                                        onChange={handleTypeChange}
                                        required
                                    >
                                        <option value="" disabled>Θέση Φόρτωσης</option>
                                        {locations.map((loc, index) => (
                                            <option key={index} value={loc.name}>{loc.name}</option>
                                        ))}
                                        <option value="Other_new">Άλλο</option>
                                    </select>
                                    {type === 'Other_new' && (
                                        <div className='form-group mb-2'>
                                            <label className='form-label' ></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='Θέση Φόρτωσης'
                                                value={manualInput}
                                                onChange={(e) => setManualInput(e.target.value)}
                                                required
                                            />
                                        </div>
                                    )}

                                </div>


                                <div className='form-group mb-2'>
                                    <label className='form-label'></label>
                                    <select
                                        name='location'
                                        className='form-control'
                                        value={location}
                                        onChange={handleLocationChange}
                                        required
                                    >
                                        <option value="" disabled hidden>Χώρος Απόρριψης</option>
                                        {locations.map((loc, index) => (
                                            <option key={index} value={loc.name}>{loc.name}</option>
                                        ))}
                                        <option value="Other">Άλλο</option>
                                    </select>
                                    {location === 'Other' && (
                                        <div className='form-group mb-2'>
                                            <label className='form-label'></label>
                                            <input
                                                type='text'
                                                placeholder='Χώρος Απόρριψης'
                                                value={customLocation}
                                                className='form-control'
                                                onChange={(e) => setCustomLocation(e.target.value)}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='form-label'></label>
                                    <input
                                        type='text'
                                        placeholder='Θέση GPS'
                                        name='coords'
                                        className='form-control'
                                        value={coords}
                                        onChange={(e) => setcoords(e.target.value)}
                                        required
                                        disabled={location !== 'Other'}
                                    />
                                </div>






                                <div className='form-group mb-2'>
                                    <label className='form-label'></label>
                                    <input type='text' placeholder='Βάρος(kg)' name='kg'
                                        className='form-control'
                                        value={kg} onChange={(e) => setkg(e.target.value)} required></input>
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='form-label'></label>
                                    <input type='text' placeholder='Υλικό' name='material' className='form-control'
                                        value={material} onChange={(e) => setmaterial(e.target.value)} required></input>
                                </div>


                                <div className='form-group mb-2'>
                                    <label className='form-label'></label>
                                    <input type='text' placeholder='Φορέας' name='organisation'
                                        className='form-control' value={organisation}
                                        onChange={(e) => setorganisation(e.target.value)} required></input>
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='form-label'>1</label>
                                    <input type='text' placeholder='Παρατηρήσεις' name='pass'
                                        className='form-control' value={pass}
                                        onChange={(e) => setpass(e.target.value)} required></input>
                                </div>
                                <div className='form-group mb-2'>
                                    <label style={{ color: 'black' }} className='form-label'>Όνομα Χρήστη: </label>
                                    <input type='text' name='adminId'
                                        className='form-control' disabled
                                        value={user.user.name} onChange={(e) => setadminId(e.target.value)}></input>
                                </div>


                            </form>
                            <button style={{ marginRight: '10px' }} className='btn btn-success' onClick={openModal} disabled={isValid}>
                                Προσθήκη
                            </button>

                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                contentLabel="Your Modal Title Here"
                                style={{
                                    content: {
                                        top: '50%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -50%)',

                                    }
                                }}>

                                <h2>Προσθήκη Δεδομένων</h2>
                                <button onClick={closeModal}>Κλείσιμο</button>
                                <input
                                    type="checkbox"
                                    checked={confirmCheck}
                                    onChange={e => setconfirmCheck(e.target.checked)}
                                /> Επιβεβαίωση
                                <button
                                    style={modalButtonStyles.confirmButton}
                                    onClick={saveWaste}
                                    disabled={!confirmCheck || isLoading} // Also consider the isLoading state
                                >Εισαγωγή</button>

                                <button
                                    style={modalButtonStyles.cancelButton}
                                    onClick={closeModal}>Άκυρο</button>
                            </Modal>
                            <button style={{ backgroundColor: 'red' }} className='btn btn-danger md-2'
                                onClick={() => navigate('/waste')}>Επιστροφή
                            </button>
                        </div>

                    </div>
                    {isLoading ? <b style={{ color: 'red', fontSize: '20px', margin: '20px 10px 0 450px' }}>Τα δεδομένα εισάγονται στο Blockchain...</b> : null}
                </div>
            </div>
        </div>
    )
}

export default AddWaste;

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '30%', // Smaller width
        height: 'fit-content', // Height according to content
        padding: '20px', // Padding inside the modal
        // Add more styling as needed
    }
};

const modalButtonStyles = {
    confirmButton: {
        backgroundColor: '#4CAF50', // Green background
        color: 'white',
        padding: '10px 20px',
        margin: '10px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        // Add more styling as needed
    },
    cancelButton: {
        backgroundColor: '#f44336', // Red background
        color: 'white',
        padding: '10px 20px',
        margin: '10px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        // Add more styling as needed
    }
};