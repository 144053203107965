import React ,{useState}from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faUser,
    faCalendar,
    faList,
    faLocationDot,
    faRoute,
    faArrowAltCircleLeft,
    faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons';
import './css/navbar.css';
import logo from "../assets/image1.png";
import useAuth from '../context/authContext';

const Navbar= () => {

    const [searchRoute, setSearchRoute] = useState('');
    const {user} = useAuth()

    const handleSignout = () => {
      localStorage.clear();
      window.location.href = '/login';
    };
  
    const handleSearchChange = (event) => {
      const path = event.target.value;
      setSearchRoute(path);
    };
  
    const navigateToSearch = () => {
      if (searchRoute) {
      
        window.location.href = searchRoute;
      }
    };
  
    return (
        <div className="sidebar">
            <img src={logo} alt="Logo" className="sidebar-logo" />

            <ul className="sidebar-nav">
                <li className="sidebar-item">
                    <a href="/waste" className="sidebar-link">
                        <FontAwesomeIcon icon={faHome} className="icon" /> Αρχική
                    </a>
                </li>
                {user.user.role === 'admin' && (
                    <>
                  
                <li className="sidebar-item">
                    <a href="/admin" className="sidebar-link">
                        <FontAwesomeIcon icon={faUser} className="icon" /> Εργαζόμενοι
                    </a>
                </li>
           
                <li className="sidebar-item">
                    <a href="/location" className="sidebar-link">
                        <FontAwesomeIcon icon={faLocationDot} className="icon"/> Τοποθεσία
                    </a>
                </li>
                </> 
                 )}
          
          
          
                <li className="sidebar-item">
                    <a href="/waste" className="sidebar-link">
                        <FontAwesomeIcon icon={faList} className="icon" /> Ροές Απορριμμάτων
                    </a>
                </li>
                <li className="sidebar-item">
                    <a href="/route" className="sidebar-link">
                        <FontAwesomeIcon icon={faRoute} className="icon" /> Δρομολόγια
                    </a>
                </li>
                {user.user.role === 'admin' && (
                <li className="sidebar-item dropdown">
                     <a href="#" className="sidebar-link dropdown-toggle">
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
                    Εύρεση Καταχώρισης
                        </a>
                    <div className="dropdown-content">
                    <a href="/search">Αναζήτηση Ροών</a>
                    <a href="/searchRoute">Αναζήτηση Δρομολογίου</a>
                    </div>
                </li>
                 )} 

                </ul>

            <div className="sidebar-item">
                <ul className="sidebar-nav">
                    <li className="sidebar-item">
                            <a  className="sidebar-link" onClick={handleSignout}>
                                <FontAwesomeIcon icon={faArrowAltCircleLeft} className="icon" /> Αποσύνδεση
                            </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Navbar;
