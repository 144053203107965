import React, {useState,useEffect} from 'react';
import './css/AdminDashboard.css';
import NavBar from './NavBar';
import SearchIcon from '@mui/icons-material/Search';
import {GreenButton} from "../components/buttonStyles";
import axios from 'axios';


const LocationsPage = () => {

    const [searchTerm, setSearchTerm] = useState('');
    const [locations, setLocations] = useState([]);

    const fetchLocations = async () => {
        try {
            const response = await fetch('https://submit.enssol.tech/api/v1/locations');
            const data = await response.json();
            setLocations(data.locations);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    }
    useEffect(()=>{
        fetchLocations()
    },[])


    const deleteEmployee = async (id) => {

        try {
            const response = await axios.delete(`https://submit.enssol.tech/api/v1/locations/${id}`);
            fetchLocations();
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    }

    const filterEmployee = locations.filter((employee) => {
        if (employee.name) {
            return (
                employee.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            return false;
        }
    });

    return (
        <div>
            <NavBar/>

            <div className='container3'>
                <section className='main'>
                    <div className='main-top' style={{display: 'flex', alignItems: 'center'}}>
                        <h3 style={{flex: '1'}}>Λίστα Τοποθεσίας</h3>
                        <div style={{marginTop: '10px'}} className="search-bar">
                            <div className="search-prefix">
                                <SearchIcon/>
                            </div>
                            <input
                                style={{color: 'black'}}
                                className="search-input"
                                type="text"
                                placeholder="Αναζήτηση Τοποθεσίας"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                    <table id="example" className="table table-striped" style={{width: "100%"}}>
                        <thead>
                        <tr>
                            <td scope='col'>No.</td>
                            <td scope='col'>Όνομα Τοποθεσίας</td>
                            <td scope='col'>GPS</td>
                            <td>Ενέργειες</td>

                        </tr>
                        </thead>
                        <tbody>
                        {filterEmployee.map((emp, index) => (

                            <tr key={emp.number}>
                                <th scope='row'>{index + 1}</th>
                                <th>{emp.name}</th>
                                <td>{emp.gps}</td>
                           
                                <td>
                                    <a href={`/edit-location/${emp._id}`} className='btn btn-info pl-3'>Ενημέρωση</a>
                                    <button className='btn btn-info pl-3' style={{backgroundColor: 'red'}}
                                            onClick={() => deleteEmployee(emp._id)}>Διαγραφή
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <a href='/add-location'>
                        <GreenButton>
                            Εισαγωγη Τοποθεσίας
                        </GreenButton>
                    </a>
                </section>
            </div>
        </div>
    );
}

export default LocationsPage;
