
import React, {useState,useEffect} from 'react';
import './css/AdminDashboard.css';
import NavBar from './NavBar';
import EmployeeService from '../services/EmployeeService';
import SearchIcon from '@mui/icons-material/Search';
import {GreenButton} from "../components/buttonStyles";
import useAuth from "../context/authContext";
import Modal from 'react-modal';
Modal.setAppElement('#root');



const AdminDashboard = () => {


    const [searchTerm, setSearchTerm] = useState('');
    const [employee, setEmployee] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const getAllUsers = async () => {
        const response = await fetch('https://submit.enssol.tech/api/v1/user/')
        const json = await response.json()

        if (response.ok) {
            setEmployee(json.users)
        }
    }
    useEffect(()=>{
        getAllUsers()
    },[])


    const openModal = (id) => {
        setSelectedEmployeeId(id);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setConfirmDelete(false);
    };

    const handleDelete = () => {
        if (confirmDelete && selectedEmployeeId) {
            EmployeeService.deleteEmployee(selectedEmployeeId).then((response) => {
                getAllUsers();
            }).catch(error => {
                console.error(error);
            });
        }
        closeModal();
    };

    const filterEmployee = employee.filter((employee) => {
        if (employee.name) {
            return (
                employee.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            return false;
        }
    });

    return (
        <div>
            <NavBar/>

            <div className='container3'>
                <section className='main'>
                    <div className='main-top' style={{display: 'flex', alignItems: 'center'}}>
                        <h3 style={{flex: '1'}}>Λίστα Χρηστών</h3>
                        <div style={{marginTop: '10px'}} className="search-bar">
                            <div className="search-prefix">
                                <SearchIcon/>
                            </div>
                            <input
                                style={{color: 'black'}}
                                className="search-input"
                                type="text"
                                placeholder="Αναζήτηση Χρήστη"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                    <table id="example" className="table table-striped" style={{width: "100%"}}>
                        <thead>
                        <tr>
                            <td scope='col'>No.</td>
                            <td scope='col'>Όνομα Χρήστη</td>
                            <td scope='col'>Όνομα</td>
                            <td scope='col'>Επώνυμο</td>
                            <td scope='col'>E-mail</td>
                            <td scope='col'>Ρόλος</td>
                            <td>Ενέργειες</td>

                        </tr>
                        </thead>
                        <tbody>
                        {filterEmployee.map((emp, index) => (

                            <tr key={emp.number}>
                                <th scope='row'>{index + 1}</th>
                                <th>{emp.username}</th>
                                <td>{emp.name}</td>
                                <td>{emp.surname}</td>
                                <td>{emp.email}</td>
                                <td>{emp.role}</td>
                                <td>
                                    <a href={`/edit-employee/${emp._id}`} className='btn btn-success'>Ενημέρωση</a>
                                    <button className='btn btn-info pl-3' style={{backgroundColor: 'red'}}
                                             onClick={() => openModal(emp._id)}>Διαγραφή
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <a href='/add-employee'>
                        <GreenButton>
                            Εισαγωγη Χρηστη
                        </GreenButton>
                    </a>
                </section>
            </div>
      
        <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Delete Confirmation"
                // Add your modal styles here
                style={customModalStyles} 
            >
                <h2>Η διαγραφή είναι τελική και μη αντιστρέψιμη, είστε σίγουροι;</h2>
                <input
                    type="checkbox"
                    checked={confirmDelete}
                    onChange={e => setConfirmDelete(e.target.checked)}
                /> Επιβεβαίωση
                <button
                style={modalButtonStyles.confirmButton}
                 onClick={handleDelete}>Διαγραφή</button>
                <button 
                style={modalButtonStyles.cancelButton}
                onClick={closeModal}>Άκυρο</button>
            </Modal>
         </div>
    );
}

export default AdminDashboard;

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '30%', // Smaller width
        height: 'fit-content', // Height according to content
        padding: '20px', // Padding inside the modal
        // Add more styling as needed
    }
};

const modalButtonStyles = {
    confirmButton: {
        backgroundColor: '#4CAF50', // Green background
        color: 'white',
        padding: '10px 20px',
        margin: '10px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        // Add more styling as needed
    },
    cancelButton: {
        backgroundColor: '#f44336', // Red background
        color: 'white',
        padding: '10px 20px',
        margin: '10px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        // Add more styling as needed
    }
};