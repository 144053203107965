/* global google */

    import React, { useState, useEffect, useRef } from 'react';
    import { GoogleMap, Marker, OverlayView, useLoadScript } from '@react-google-maps/api';
    import { areCoordinatesEqual } from './utils';
    
    const WasteMap = ({ coordinate, coordinates, data, resetMap, onMarkerClick,onOverlayClose,handleMarkerSelect }) => {
      const mapRef = useRef(null);
      const [selectedMarker, setSelectedMarker] = useState(null);
      const [selectedMarkerData, setSelectedMarkerData] = useState(null);
      const [defaultCenter, setDefaultCenter] = useState({ lat: 37.9838, lng: 23.7275 });
      const [lastCenter, setLastCenter] = useState(null); // New state to track the last center position
      const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDHBYBvO7qVNwES1cKUkOLr5QmuY2STmEA' // Replace with your API key
      });


      // In WasteMap.js
const onMarkerSelect = (markerData) => {
  handleMarkerSelect(markerData); // Using the function passed from WasteList
}

   ///////

   const updateMapBounds = () => {
    if (!mapRef.current || !coordinates.length) return;

  const bounds = new google.maps.LatLngBounds();
  coordinates.forEach((coord) => {
    bounds.extend(new google.maps.LatLng(coord.lat, coord.lng));
  });

  
  if (coordinates.length === 0) {
    // Set the map to a default location if coordinates are empty
    const defaultLocation = { lat: 37.9838, lng: 23.7275 }; // Adjust this to your desired default location
    mapRef.current.panTo(defaultLocation);
    mapRef.current.setZoom(6); // Adjust the zoom level as needed
    return;
  }
  
    if (coordinates.length === 1) {
      // If there's only one marker, set center and a reasonable zoom level
      mapRef.current.setCenter(bounds.getCenter());
      mapRef.current.setZoom(15); // You can adjust this value as needed
    } else {
      // Add padding to bounds and optionally set a max zoom level
      const paddingOptions = {
        left: 50, // in pixels
        right: 50,
        top: 50,
        bottom: 50
      };
      mapRef.current.fitBounds(bounds, paddingOptions);
  
      // Listen for zoom_changed event to enforce a max zoom level
      const zoomChangedListener = google.maps.event.addListener(mapRef.current, 'zoom_changed', () => {
        const maxZoom = 17; // Adjust as needed
        if (mapRef.current.getZoom() > maxZoom) {
          google.maps.event.removeListener(zoomChangedListener);
          mapRef.current.setZoom(maxZoom);
        }
      });
    }

    const center = bounds.getCenter();
    setDefaultCenter({ lat: center.lat(), lng: center.lng() });
  };

  useEffect(() => {
    if (selectedMarker) {
      // Reset the selected marker and its data
      setSelectedMarker(null);
      setSelectedMarkerData(null);
    }
    updateMapBounds();
  }, [coordinates]);
  ///////

    
      useEffect(() => {
        // Close any open markers if the coordinates array has changed
        if (prevCoordinates.current && !areArraysEqual(prevCoordinates.current, coordinates)) {
          closeOverlay();
        }
      
        setSelectedMarkerFromProps();
        prevCoordinates.current = coordinates;
      }, [coordinate, coordinates, data]); // Dependencies


      // Helper function to check if two arrays of coordinates are equal
      function areArraysEqual(array1, array2) {
        if (array1.length !== array2.length) {
          return false;
        }
      
        return array1.every((element, index) => {
        
          return areCoordinatesEqual(element, array2[index]);
        });
      }
      
      // Initialize a ref to keep track of the previous coordinates
      const prevCoordinates = useRef(coordinates);
    
      const resetMapHandler = () => {
        if (mapRef.current) {
          const defaultMapCenter = { lat: 37.9838, lng: 23.7275 };
          const defaultZoomLevel = 6;
          mapRef.current.panTo(defaultMapCenter);
          mapRef.current.setZoom(defaultZoomLevel);
        }
        setSelectedMarker(null);
        setSelectedMarkerData(null);
      };
    
      useEffect(() => {
        if (resetMap) {
          resetMapHandler();
        }
      }, [resetMap]);


    
      const setSelectedMarkerFromProps = () => {
        if (coordinate) {
          const index = coordinates.findIndex((c) => areCoordinatesEqual(coordinate, c));
          if (index !== -1) {
            setSelectedMarker(coordinates[index]);
            setSelectedMarkerData(data[index]);
            // // Optional: Center the map on the new selected marker
             mapRef.current && mapRef.current.panTo(coordinates[index]);
          }
        }
      };
      
      const handleMarkerClick = (index) => {
        const selectedCoordinate = coordinates[index];
        setSelectedMarker(selectedCoordinate);
        setSelectedMarkerData(data[index]);
        setLastCenter(selectedCoordinate); // Update the last center position
        onMarkerClick(selectedCoordinate);
        mapRef.current && mapRef.current.panTo(selectedCoordinate);
    };
    
      const getMarkerIcon = (coordinateItem) => {
        const isSelected = selectedMarker && areCoordinatesEqual(selectedMarker, coordinateItem);

        return isSelected
          ? 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png' // Selected marker color
          : 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';  // Default marker color
    };
    
    const markers = coordinates.map((coordinateItem, index) => (
      <Marker
          key={index}
          position={{ lat: coordinateItem.lat, lng: coordinateItem.lng }}
          icon={getMarkerIcon(coordinateItem)}
          onClick={() => handleMarkerClick(index)}
      />
  ));
    
      const closeOverlay = () => {
        setSelectedMarker(null);
        setSelectedMarkerData(null);
        if (onOverlayClose) {
          onOverlayClose(); // Call the callback function when the overlay is closed
        }
      };
    
      return (
        // <div style={{ height: '800px', maxWidth: '90vw'  }}>
            <div style={{ height: '800px', width: '100%', maxWidth: 'calc(100% - 40px)',boxSizing:'border-box' }}>
          {isLoaded && !loadError && (
           <GoogleMap
           onLoad={(map) => (mapRef.current = map)}
           mapContainerStyle={{ height: '100%', width: '100%',boxSizing: 'border-box' }}
           center={lastCenter || defaultCenter} // Use lastCenter or defaultCenter
          zoom={6}
         >
              {markers}
              {selectedMarker && selectedMarkerData && (
                <OverlayView
                  position={selectedMarker}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={(width, height) => ({
                    x: -width / 2,
                    y: -height,
                  })}
                  zIndex={1000}
                >
                  <CustomOverlay data={selectedMarkerData} onClose={closeOverlay} />
                </OverlayView>
              )}
            </GoogleMap>
          )}
          {loadError && <div>Error loading Google Maps</div>}
        </div>
      );
    };
    
    const CustomOverlay = ({ data, onClose }) => {
  
      return (
        <div style={{ padding: '15px', background: 'white', border: '1px solid #ccc', minWidth: '200px', position: 'relative', pointerEvents: 'auto' }}>
          <div onClick={(e) => { onClose(); e.stopPropagation(); }} style={{ cursor: 'pointer', position: 'absolute', top: '2px', right: '5px', fontSize: '25px' }}>
            &times;
          </div>
          <p style={{ fontSize: '16px', marginBottom: '10px' }}>Βάρος (kg): {data.kg}</p>
          <p style={{ fontSize: '16px', marginBottom: '10px' }}><i className="fa fa-calendar" aria-hidden="true"></i> {data.date} {data.time}</p>
          <p style={{ fontSize: '16px' }}>Όχημα: {data.vehicle}</p>
        </div>
      );
    };
    
    export default WasteMap;
    