import React, {useState} from 'react'
import {Bars} from "react-loader-spinner";

function useLoader() {
    const [isLoading, setLoading] = useState(true);
    function Loader() {
        return (
            <div style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Bars
                    height="80"
                    width="80"
                    radius="9"
                    color="black"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                />
            </div>
        )
    }

    return {isLoading, setLoading, Loader}
}

export default useLoader
