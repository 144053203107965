import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import logo from "../assets/image1.png";
import 'bootstrap/dist/css/bootstrap.min.css';

const LandingPage = () => {
    return (
        <Container className="h-100 d-flex justify-content-center align-items-center">
            <Row className="justify-content-center align-items-center text-center">
                <Col xs={12} md={8} className="mt-2">
                    <img src={logo} alt="ensoll" style={{width: '200px'}}/>
                </Col>

                <Col xs={12} md={8} className="mt-5">
                    <div className="p-3" style={{height: '50vh'}}>
                        <div style={{minHeight: 'calc(100vh - 250px)'}}>
                            <Row style={{minHeight: '20vh'}}>
                                <h1 className="text-center" style={{
                                    fontSize: '24px',
                                    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;'
                                }}>
                                    Πλατφόρμα Διαχείρισης Απορριμμάτων Τεχνολογίας Blockchain
                                </h1>
                                <p style={{
                                    fontSize: '18px',
                                    lineHeight: '1.6',
                                    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;'
                                }}>
                                    Παροχή σύγχρονων και αξιόπιστων τεχνολογικά και
                                    ποιοτικά λύσεων στον τομέα των έξυπνων πόλεων και της ηλεκτροκίνησης, συνδυάζοντας
                                    ολοκληρωμένες λύσεις (προϊόντα) υψηλής προστιθέμενης αξίας με τεχνολογίες Internet of Things (IoT) και Blockchain.
                                </p>
                            </Row>
                            <br/>
                            <hr/>
                            <div className='d-flex flex-column align-items-center justify-content-center gap-3 p-3'>
                                <Link to="/login" className="text-decoration-none">
                                    <Button variant="success" size="lg" fullWidth>
                                        ΣΥΝΔΕΣΗ
                                        {/* Include your login icon here */}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <footer style={{
                            color: '#215D0F',
                            height: "50px"
                        }}>
                            <p>
                                &copy; 2023 ENSSOL A.E. – Balcan Center, Επίπεδο 1, Πτέρυγα Δ, Χώρος 9 / Τ.Κ.: 57001 / Τηλ. 2310 477087 / E-mail: central@enssol.gr
                            </p>
                        </footer>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default LandingPage;
