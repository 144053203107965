
export const areCoordinatesEqual=(coord1, coord2)=> {
  if (!coord1 || !coord2) {
      return false;
  }

  // Function to extract latitude and longitude from a coordinate
  const extractLatLng = (coord) => {
      if (Array.isArray(coord)) {
          // If coordinate is an array
          return coord.map(Number);
      } else if (coord && typeof coord === 'object') {
          // If coordinate is an object
          return [coord.lat, coord.lng].map(Number);
      } else {
          // If coordinate is a string or other format
          let normalizedCoord = coord.toString().replace(/[\[\]]/g, '');
          return normalizedCoord.split(/\s*,\s*/).map(Number);
      }
  };

  const [lat1, lng1] = extractLatLng(coord1);
  const [lat2, lng2] = extractLatLng(coord2);

  return lat1 === lat2 && lng1 === lng2;
}

